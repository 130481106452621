<template>
	<div>

		<table class="table">
			<thead>
			<tr>
				<th>GAME</th>
				<th>CERTIFICATIONS</th>
				<th>ENABLED</th>
				<th>RELEASE DATE</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="game in sortedList"
					:key="game.Id">
				<td>
					<router-link
							class="mr-2"
							:to="{name: 'games-game_id', params: {game_id: game.Id}}">
						<img class="img-fluid rounded" :src="game.Assets['icon-256x256']" :alt="game.Name" :title="game.Name"
								 style="max-width: 48px">
					</router-link>
					<router-link
							:to="{name: 'games-game_id', params: {game_id: game.Id}}">{{ game.Name }}
					</router-link>
				</td>
				<td class="text-uppercase">{{ (game.Certifications ? game.Certifications : []).join(" / ") }}</td>
				<td>
					<i class="fa fa-circle" :style="{'color': game.Enabled ? '#2DCC71' : 'Red'}"></i>
				</td>
				<td>
					{{ game.CreatedAt|moment("LL") }}
				</td>
			</tr>
			</tbody>
		</table>

		<router-link class="btn btn-primary" :to="{name: 'games-new'}">New Game</router-link>

		<div class="my-5"></div>

	</div>
</template>

<script>
import api from '@/api'

export default {
	name: "index",
	data: () => ({list: [], loading: false}),
	async mounted() {
		this.list = await api.Games.getList()
	},
	computed: {
		sortedList() {
			return this.list.slice().reverse()
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/assets/style/_palette";

@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

.games-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
	grid-gap: 10px;

	.game-item {
		position: relative;
		border-radius: 12px;

		&:after {
			position: absolute;
			top: 10px;
			right: 10px;
			background-color: red;
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 5px;
		}

		&.enabled:after {
			background-color: $color-primary;
			box-shadow: 0 0 0 2px darken($color-primary, 40%);
		}

		&.disabled:after {
			background-color: red;
			box-shadow: 0 0 0 2px darken(red, 40%);
			animation: blink-animation 1s steps(5, start) infinite;
		}
	}
}
</style>
